/* global $ */

function init() {
  /**
   * Workaround for Gravity Forms missing filter for Consent field markup.
   */
  $(document).on('gform_post_render', () => {
    [...document.querySelectorAll('.gfield_consent_label')].forEach((el) => {
      const markerHtml = `<span class="marker">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z "/>
        </svg>
      </span>`;
      el.insertAdjacentHTML('beforebegin', markerHtml);
    });
  });
}

export default {
  init,
};
