// import 'alpinejs';
const videoLinkEls = document.querySelectorAll('.yt-video-link');

function init() {

  if (videoLinkEls.length) {
    [...videoLinkEls].forEach((el) => {
      el.addEventListener('click', handleClick);
      var dataUrl = el.getAttribute('data-yt-url');
      var videoId = YouTubeGetID(dataUrl);
      var thumbUrl = 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg';
      el.querySelector('img').setAttribute('src', thumbUrl);
    });
  }
}

function handleClick(event) {
  event.preventDefault();
  var dataUrl = this.getAttribute('data-yt-url');
  var videoId = YouTubeGetID(dataUrl);
  var videoFrame = document.querySelector('.b-youtube-player__video > iframe');
  var embedUrlBase = "https://www.youtube.com/embed/";
  var videoUrl = embedUrlBase + videoId + "?autoplay=1";

  /*
  [...videoLinkEls].forEach((el) => {
    el.classList.remove('current-video');
  });
  this.classList.add('current-video');
  */

  videoFrame.setAttribute('src', videoUrl);
  document.getElementById('video-container').scrollIntoView();
}

function YouTubeGetID(url){
  var ID = '';
  url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if(url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  return ID;
}


export default {
  init,
};
