function init() {

  let searchParams = new URLSearchParams(window.location.search)
  let $searchToggle = $('a.search-toggle');
  let $siteSearch = $('.c-site-search');

  $( $searchToggle ).attr('aria-expanded', 'false');
  $( $searchToggle ).attr('aria-controls', 'site-search');

  if ( searchParams.has('s') ) {
    $( $siteSearch).show();
    $( $siteSearch).css('height', 'auto');

    $( $siteSearch ).attr('aria-hidden', 'false');
    $( $searchToggle ).addClass('search-open');
    $( $searchToggle ).attr('aria-expanded', 'true');
    $( $searchToggle ).attr('aria-current', 'true');
    $('.search-field').focus();
  }
  else {
    $( $siteSearch ).hide();
    $( $siteSearch).css('height', '0');
    $( $siteSearch ).attr('aria-hidden', 'true');
    $( $searchToggle ).attr('aria-expanded', 'false');
  }

  $( $searchToggle ).on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $( $siteSearch).css('height', 'auto');

    $( $searchToggle ).toggleClass('search-open');
    $( $siteSearch ).slideToggle();

    if ( $(this).hasClass('search-open') ) {
      $('.search-field').focus();
      $( $siteSearch ).attr('aria-hidden', 'false');
      $( $searchToggle ).attr('aria-expanded', 'true');
      window.scrollTo(0, 0);
    } else {
      $('.c-site-search').attr('aria-hidden', 'true');
      $( $searchToggle ).attr('aria-expanded', 'false');
    }
  });

}

export default {
  init,
};
