import googleMapsApiLoader from 'google-maps-api-loader';

function init() {
  const mapEls = document.querySelectorAll('.b-google-map') || [];

  if (!mapEls.length) {
    return;
  }

  const { apiKey } = mapEls[0].querySelector('.b-google-map__canvas').dataset;

  googleMapsApiLoader({
    libraries: ['places'],
    apiKey,
  }).then((googleApi) => {
    mapEls.forEach((mapEl) => {
      const mapCanvasEl = mapEl.querySelector('.b-google-map__canvas') || [];
      const markerEls = mapEl.querySelectorAll('data');

      const initialCenter = {
        lat: Number(markerEls[0].dataset.lat),
        lng: Number(markerEls[0].dataset.lng),
      };

      const bounds = new googleApi.maps.LatLngBounds();
      const infowindow = new googleApi.maps.InfoWindow();
      const map = new googleApi.maps.Map(mapCanvasEl, {
        center: initialCenter,
        zoom: 13,
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: false,
      });

      markerEls.forEach((markerEl) => {
        const marker = new googleApi.maps.Marker({
          position: {
            lat: Number(markerEl.dataset.lat),
            lng: Number(markerEl.dataset.lng),
          },
          title: markerEl.textContent,
          // map,
        });

        marker.setMap(map);

        bounds.extend(marker.getPosition());

        marker.addListener('click', () => {
          infowindow.setContent(markerEl.textContent);
          infowindow.open(map, marker);
        });

        return marker;
      });

      googleApi.maps.event.addListenerOnce(map, 'bounds_changed', () => {
        if (map.getZoom() > 13) {
          map.setZoom(13);
        }
      });

      map.fitBounds(bounds);
    });
  }, (err) => {
    console.error(err);
  });
}

export default {
  init,
};
