function handleClick(event) {
  const buttonEl = event.currentTarget;
  const navEl = buttonEl.closest('.nav-sites');
  const buttonControls = buttonEl.getAttribute('aria-controls').split(' ');
  const submenuEl = navEl.querySelector(`#${buttonControls[0]}`);
  const isExpanded = buttonEl.getAttribute('aria-expanded') === 'true';

  $(".top-bar button").attr('aria-expanded', false);
  $(".top-bar .menu__submenu").attr('aria-hidden', true);

  buttonEl.setAttribute('aria-expanded', !isExpanded);
  submenuEl.setAttribute('aria-hidden', isExpanded);
}

function init() {
  const navEls = document.querySelectorAll('.nav-sites');
  const level3s = document.querySelectorAll('.nav-sites a.level-3.current-menu-item');
  let is_anchor;

  // DISABLED: Twig used to catch links with '#' in them
  // Remove .current-menu-item from anchor links as ALL of those are 'current' when on same the page
  /*
  [...level3s].forEach((level3) => {
    is_anchor = level3.href.indexOf('#');
    if (is_anchor > -1) {
      level3.classList.remove('current-menu-item');
    }
  });
  */

  [...navEls].forEach((navEl) => {
    [...navEl.querySelectorAll('[aria-controls]')].forEach((buttonEl) => {
      buttonEl.addEventListener('click', handleClick);
    });
    // Close #nav-panel on ALL clicks including anchor links that do not cause page reloading
    /*
    [...navEl.querySelectorAll('.menu__anchor')].forEach((linkEl) => {
      linkEl.addEventListener('click', closeNavPanel);
    });
    */
  });

}

/*
function closeNavPanel() {
  document.querySelector('#nav-panel').setAttribute('aria-hidden', true);
  document.querySelector('[data-action="nav-panel-toggle"]').setAttribute('aria-expanded', false);
  document.body.classList.remove('nav-panel-expanded');
}
*/

export default {
  init,
};
