// import 'alpinejs';

  const modal = {
    callOpen: () => {
      modal.opener = document.activeElement;
      openModal();
    },
    callClose: () => {
      closeModal(modal.opener);
    },
    handleOverlayClick: (event) => {
      if(event.target.className === 'overlay') {
        closeModal(modal.opener);
      }
    }
  }

function init() {

  const openButtons = document.querySelectorAll('.open-quiz-modal');
  const closeButtons = document.querySelectorAll('.close-modal');
  const quiz = document.querySelector('.b-crystal-ball-quiz');
  const overlay = document.querySelector('.overlay');

  if (quiz) {
    attachEventListener(openButtons, closeButtons, overlay);
  }

}

function openModal(){
  document.querySelector('.interest-select').value = 0;
  setVisible(true);
  setFocus();
  setInertBehindModal(true);
}

function closeModal(opener){
  document.querySelector('.interest-select').value = 0;
  setVisible(false);
  setInertBehindModal(false);
  opener.focus();
}

function attachEventListener(openButtons, closeButtons, overlay) {
  openButtons.forEach(b => {
    b.addEventListener('click', modal.callOpen);
  });
  closeButtons.forEach(b => {
    b.addEventListener('click', modal.callClose);
  });
  overlay.addEventListener('click', modal.handleOverlayClick)
  window.addEventListener('keydown', clallIfEscPress)
}

function setVisible(visible) {
  const display = visible? 'block' : 'none';
  document.querySelector('.overlay').style.display = display;
}

function setFocus() {
  document.querySelectorAll('.modal button, modal input, modal textarea, modal select')[0].focus();
}


function setInertBehindModal(inertStatus) {
  const skip_link = document.querySelector('.skip-link');
  const top_bar = document.querySelector('.top-bar');
  const footer = document.querySelector('.c-site-footer');

  const blocks_area_elements = document.querySelector('.blocks-area').querySelectorAll('*');
  const quiz_elements = document.querySelector('.b-crystal-ball-quiz').querySelectorAll('*');

  if (inertStatus === true) {
    skip_link.setAttribute('inert', true);
    top_bar.setAttribute('inert', true);
    footer.setAttribute('inert', true);

    blocks_area_elements.forEach(element => {
      element.setAttribute('inert', true)
    });

    quiz_elements.forEach(element => {
      element.removeAttribute('inert')
    });

    //document.querySelector('.b-crystal-ball-quiz button.open-quiz-modal').setAttribute('inert', true);
    document.querySelector('.b-crystal-ball-quiz').removeAttribute('inert');
  }
  else {
    skip_link.removeAttribute('inert');
    top_bar.removeAttribute('inert');
    footer.removeAttribute('inert');

    blocks_area_elements.forEach(element => {
      element.removeAttribute('inert')
    });

    quiz_elements.forEach(element => {
      element.removeAttribute('inert')
    });

    //document.querySelector('.b-crystal-ball-quiz button.open-quiz-modal').removeAttribute('inert');
    document.querySelector('.b-crystal-ball-quiz').removeAttribute('inert');
  }

}

function clallIfEscPress(event){
  if(event.key === 'Escape') {
    modal.callClose();
  }
}

export default {
  init,
};
